@import 'bulma';
@import 'bulma-switch';
/* @import 'bulma-checkradio'; */
@import 'bulma-divider';

/* $fa-font-path: '@fortawesome/fontawesome-free/webfonts' !default; */

@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';

.has-margin-on-top {
    margin-top: 0.75rem;
}

.has-small-margin {
    margin: 0.37rem;
}

.has-small-margin-on-top {
    margin-top: 0.37rem;
}

.has-small-column-gap {
    column-gap: 0.25rem;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
}

svg {
    text {
        font-family: 'Ubuntu';
    }
}

body {
    padding-bottom: 75px;
    font-family: 'Ubuntu';
}

body:has(#game-screen) {
    padding-bottom: 0;
}

ul.list {
    padding-left: 1rem;

    &.has-disc {
        list-style: disc;
    }
}

ol.list {
    padding-left: 1rem;
}

.text-align-center {
    text-align: center;
}

.rules-text {
    p {
        margin-bottom: 0.75rem;
    }

    .instructions {
        p {
            margin-bottom: 0rem;
        }
    }

    figcaption {
        font-style: italic;
    }

    dt {
        font-weight: bold;
    }

    dd {
        padding-left: 0.75rem;
        font-style: italic;
    }
}

#svg-primary-button {
    fill: '#00d1b2';

    &:hover {
        /* fill: '#00c4a7'; */
        fill: 'red';
    }
}

$color-player-a: #449ee7;
$color-player-b: #d55d14;

.is-player-a {
    &.tag {
        background-color: $color-player-a;
    }
}

.has-background-color-player-a {
    background-color: $color-player-a;
}

.has-background-color-player-b {
    background-color: $color-player-b;
}

.has-color-player-a {
    color: $color-player-a;
}

.has-color-player-b {
    color: $color-player-b;
}

.is-player-b {
    &.tag {
        background-color: $color-player-b;
    }
}

.dice-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-template-rows: auto;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
}

.scenario-list {
    display: flex;
    flex-wrap: wrap;

    .scenario-list-item {
        width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:last-child {
            margin-bottom: 1.5rem;
        }
    }
}

#game-screen {
    display: grid;
    height: 100vh;
    width: 100vw;
}

.game-instructions {
    width: 100%;
    border-bottom: solid 1px #ccc;
    background-color: #fefefe;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.game-map-container {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    overflow: scroll;
}

.hex-size-controls {
    position: absolute;
    bottom: 1rem;
    opacity: 0.7;
}

.action-panel {
    border-bottom: solid 1px #ccc;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.8);
    color: rgba(255, 255, 255, 1);
    z-index: 30;

    .action-panel-scrollable {
        max-height: calc(100vh - 150px);
        overflow: scroll;
        border-bottom: solid 1px #777;
        padding-bottom: 0.5rem;
    }
}

.game-header {
    display: grid;
    width: 100%;

    .game-navigation-button {
        grid-area: button;
    }

    .game-stats-scenario-name {
        grid-area: scenario;
    }

    .game-stats-turn {
        grid-area: turn;
    }

    .game-stats-score {
        grid-area: score;
    }

    .game-stats-victory {
        grid-area: victory;
    }

    .game-stats-round {
        grid-area: round;
    }
}

.game-navigation-button {
    .icon-with-text {
        display: none;
    }
}

.game-stats-scenario-name {
    h1 {
        .icon-text {
            flex-wrap: nowrap;
        }
    }
}

.game-stats-turn {
    .icon-text {
        flex-wrap: nowrap;
        white-space: nowrap;
    }
}

.game-stats-score {
    display: flex;

    .score {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}


.tutorial-game-stats {
    display: grid;
    width: 100%;

    .game-stats-scenario-name {
        grid-area: scenario;
    }

    .game-stats-turn {
        grid-area: turn;
    }

    .game-stats-score {
        grid-area: score;
    }

    .game-stats-victory {
        grid-area: victory;
    }

    .game-stats-round {
        grid-area: round;
    }
}

@media screen and (max-width: 768px) {
    #game-screen {
        grid-template-columns: 100%;
        grid-template-rows: min-content auto;
        grid-template-areas: "header"
            "map";
    }

    .game-header-with-instructions {
        grid-area: header;
    }

    .action-panel {
        grid-area: map;
        width: 100%;
        height: fit-content;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .game-map-container {
        grid-area: map;
    }

    .game-over-message {
        grid-area: map;
        z-index: 25;
        padding: 0.5rem;
        height: fit-content;
    }

    .hex-size-controls {
        left: 0;
        margin-left: 0.5rem;
    }

    .game-header {
        padding: 0 0.5rem 0.25rem;
        width: 100%;
        border-bottom: solid 1px #ccc;
        grid-template-columns: repeat(5, fit-content);
        grid-template-rows: min-content min-content;
        grid-template-areas: "button scenario scenario scenario scenario"
            "button turn score victory round";
        justify-content: start;
        column-gap: 0.5rem;

        .game-navigation-button {
            width: fit-content;
            place-self: center;
        }

        .game-stats-scenario-name {
            overflow: hidden;
            justify-self: stretch;

            h1 {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .tutorial-game-stats {
        width: 100%;
        grid-template-columns: repeat(5, fit-content);
        grid-template-rows: min-content min-content;
        grid-template-areas: "scenario scenario scenario scenario"
            "turn score victory round";
        justify-content: start;
        column-gap: 0.5rem;

        .game-stats-scenario-name {
            overflow: hidden;
            justify-self: stretch;

            h1 {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

@media screen and (min-width: 769px) {
    #game-screen {
        grid-template-columns: 200px auto;
        grid-template-rows: 100vh;
        grid-template-areas: "header map"
    }

    .game-header-with-instructions {
        grid-area: header;
        border-right: solid 1px #ccc;
    }

    .action-panel {
        grid-area: map;
        width: fit-content;
        height: fit-content;
        justify-self: center;
        margin-top: 1rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-bottom-width: 0;
        border-radius: 4px;

        .action-panel-scrollable {
            max-height: calc(100vh - 90px);
        }
    }

    .game-instructions {
        border-bottom-width: 0;
    }

    .game-map-container {
        grid-area: map;
    }

    .game-over-message {
        grid-area: map;
        z-index: 25;
        padding: 0.5rem;
        margin: 1rem;
        height: fit-content;
        justify-self: center;
    }

    .hex-size-controls {
        left: 0;
        margin-left: 0.5rem;
    }

    .game-header {
        padding: 0.25rem 0.25rem 0.5rem;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(5, min-content);
        grid-template-areas: "button button"
            "scenario scenario"
            "victory round"
            "turn turn"
            "score score";
        justify-content: start;
        row-gap: 0.25rem;

        .game-navigation-button {
            width: 100%;
            justify-content: start;

            .icon-with-text {
                display: inline;
            }

            .icon-only {
                display: none;
            }
        }

        .game-stats-scenario-name {
            h1 {
                width: 100%;
                text-overflow: ellipsis;
            }
        }

        .game-stats-score {
            justify-self: center;
            font-size: 2rem;
        }
    }

    .tutorial-game-stats {
        width: 200px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(5, min-content);
        grid-template-areas: "scenario scenario"
            "victory round"
            "turn turn"
            "score score";
        justify-content: start;
        row-gap: 0.25rem;

        .game-stats-score {
            font-size: 2rem;
        }
    }
}

@media screen and (min-width: 1024px) {
    #game-screen {
        position: relative;
    }

    .action-panel {
        align-self: center;
        justify-self: start;
        margin: 0;
        margin-left: 3rem;
        // position: absolute;
        // top: 0;
        // left: 0;
    }

    .game-over-message {
        margin: 1rem;
        justify-self: start;
    }
}

.combat-preview-unit-container {
    width: 110px;
}

.combat-equation-braces {
    font-size: 50px;
    margin: 0.25rem;
}

.combat-preview-divider {
    border-top: solid 3px white;
    width: 100%;
    margin-top: 0.5rem;
}

.tutorial-game-screen {
    position: relative;

    .tutorial-action-panel {
        background-color: rgba(0, 0, 0, 0.8);
        color: rgba(255, 255, 255, 1);
        padding: 0.5rem;
        border-bottom: solid 1px #ccc;
        width: 100%;
        margin-top: -0.5rem;
        position: absolute;
        z-index: 30;

        .action-panel-scrollable {
            max-height: calc(100vh - 150px);
            overflow: scroll;
            border-bottom: solid 1px #777;
            padding-bottom: 0.5rem;
        }
    }
}

.responsive-svg-map {
    max-width: 100%;
    max-height: calc(100% - 0.5rem);
}

.tutorial-menu {
    background-color: #dedede;
}
